<template>
  <div>
    <b-row>
      <b-col
        md="12"
        lg="10"
        xl="8"
      >
        <b-card class="mb-3">
          <div class="mb-2">
            Paste this installation code into the &lt;head&gt; of any page on your church website where you want
            visitplanner to be installed.
          </div>
          <pre
            id="code"
            class="mb-2 p-2 force-select"
          >{{ embedCode }}</pre>
          <a
            :href="standaloneFormUrl"
            target="_blank"
          >Standalone form</a>
          <div class="text-right">
            <b-button
              variant="primary"
              @click="copyTestingCode"
            >
              Copy to clipboard
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="h4 mb-2">
          Use one of the following services? Click below to see specific easy-install instructions.
        </div>
        <b-row>
          <b-col sm="auto">
            <b-card
              no-body
              class="instruction text-center"
            >
              <router-link to="/installation/wordpress">
                <b-card-img :src="require('@/assets/images/installation/wordpress.svg')" />
              </router-link>
            </b-card>
          </b-col>
          <b-col sm="auto">
            <b-card
              no-body
              class="instruction text-center"
            >
              <router-link to="/installation/wix">
                <b-card-img :src="require('@/assets/images/installation/wix.svg')" />
              </router-link>
            </b-card>
          </b-col>
          <b-col sm="auto">
            <b-card
              no-body
              class="instruction text-center"
            >
              <router-link to="/installation/squarespace">
                <b-card-img :src="require('@/assets/images/installation/squarespace.svg')" />
              </router-link>
            </b-card>
          </b-col>
          <b-col sm="auto">
            <b-card
              no-body
              class="instruction text-center"
            >
              <router-link to="/installation/weebly">
                <b-card-img :src="require('@/assets/images/installation/weebly.svg')" />
              </router-link>
            </b-card>
          </b-col>
          <b-col sm="auto">
            <b-card
              no-body
              class="instruction text-center"
            >
              <router-link to="/installation/churchco">
                <b-card-img :src="require('@/assets/images/installation/churchco.png')" />
              </router-link>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardImg,
  BCol,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AccountService from '@/services/AccountService'
import store from '@/store'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardImg,
  },
  directives: {
    Ripple,
  },
  data() {
    const user = store.getters['auth/authUser']
    return {
      embedCode: null,
      standaloneFormUrl: `${process.env.VUE_APP_API_URL}/e/${user.account.hash}`,
    }
  },
  computed: {},
  created() {
    this.getEmbedCode()
  },
  methods: {
    copyTestingCode() {
      const codeContent = document.querySelector('#code').textContent
      const tempInput = document.createElement('input')
      tempInput.value = codeContent
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: 'BellIcon',
          text: 'The code has been successfully copied',
          variant: 'success',
        },
      })
    },
    getEmbedCode() {
      AccountService.getEmbedCode()
        .then(apiResponse => {
          if (apiResponse.data.success) {
            this.embedCode = apiResponse.data.data.trim()
          }
        })
    },
  },
}
</script>
<style scoped>
.card {
  min-width: 175px;
}

.force-select {
  -webkit-user-select: all; /* Chrome 49+ */
  -moz-user-select: all; /* Firefox 43+ */
  -ms-user-select: all; /* No support yet */
  user-select: all; /* Likely future */
}

.instruction {
  padding: 10px 10px;
}

.instruction:hover {
  box-shadow: 0 8px 25px -8px #ccc;
  cursor: pointer;
}

.instruction .card-img {
  height: 50px;
  margin: auto;
  max-width: 150px;
  object-fit: contain;
  padding: 10px 0;
  width: 100%;
}
</style>
